<template>
  <div class="index main_top"> 
        <!-- mobile search -->
        <div class="mobile_search">
          <div class="search_box hidden-sm-and-up flex_center" @click="toPage('/search')">
            <div class="icon-search iconfont search_icon flex_center"></div>
            <span> {{$t('key4')}}</span>
          </div>
        </div>
        <div class="grid_content">
            <div class="head_swiper">
                <swiper :options="swiperOption" ref="mySwiper" class="swiper_content">
                    <swiper-slide class="slide_content" v-for="(i,index) in bannerList" :key="index">
                    <img :src="i.image" :data-src="i.image" alt="" class="slider_img" />
                   </swiper-slide>
                </swiper>
                <!-- <div class="swiper-pagination pagination_state" slot="pagination"></div> -->
            </div>
            <div class="container">
                <div class="tab_box flex_start_center">
                    <div class="tab flex_start_center " v-for="(item,index) in categoryOption" :key="index" :class="[categoryId==item.id?'tab_acticve':'']" @click="getCategory(item.id)">
                        <div class="icon_tab"><img :src="item.pic" alt=""></div>
                        <div class="tab_text">{{ item.tranName }}</div>
                    </div>
                    
                </div>
                <div class="pic_thumb" >
                    <div class="grid grid_sm_gap pic_tgumb_content" :style="{'max-height':loadMore?'none':'408px'}">
                        <div class="grid_md_4 grid_sm_6 thumb_group hidden-xs-only" v-for="(item,index) in gameList" :key="index" @click="toPage('/details',item.id)">
                            <div class="group_centent flex">
                                <div class="group_img"><img :src="item.upImage" alt=""></div>
                                <div class="group_info">
                                    <div class="pic_name">{{ item.tranName }}</div>
                                    <div class="pic_cell flex_start_center">
                                        <div class="pic_num"><span>5.0</span></div>
                                        <div class="pic_start flex_center">
                                            <el-rate v-model="rateNum" disabled></el-rate>
                                        </div>
                                        <div class="pic_review">1207 {{$t('key16')}}</div>
                                    </div>
                                    <div class="pic_sold">200k+ {{$t('key17')}}</div>
                                </div>
                                <!-- <div class="cell_off">-21%</div> -->
                            </div> 
                        </div>
                    </div>
                    
                    <div class="grid_xs_12 hidden-sm-and-up goods_swiper">
                        <swiper :options="goodsOption" ref="mySwiper" class="swiper_content">
                          <swiper-slide class="slide_content" v-for="(i,index) in swiperGameList" :key="index">
                            <div class="thumb_group " v-for="(v,v_inx) in i" :key="v_inx">
                                <div class="group_centent flex" @click="toPage('/details',v.id)">
                                    <div class="group_img"><img :src="v.upImage" alt=""></div>
                                    <div class="group_info">
                                        <div class="pic_name">{{ v.tranName }}</div>
                                        <div class="pic_cell flex_start_center">
                                            <div class="pic_num"><span>5.0</span></div>
                                            <div class="pic_start flex_center">
                                                <el-rate v-model="rateNum" disabled></el-rate>
                                            </div>
                                            <div class="pic_review">1207 {{$t('key16')}}</div>
                                        </div>
                                        <div class="pic_sold">200k+ {{$t('key17')}}</div>
                                    </div>
                                    <!-- <div class="cell_off">-21%</div> -->
                                </div> 
                            </div>
                          </swiper-slide>
                        </swiper>
                        <div class="swiper_pagination_content">
                            <div class="swiper-pagination2 pagination_state" slot="pagination"></div>
                        </div>
                        
                    </div>
                    <div class="grid_md_12 more flex_center hidden-xs-only" :class="[!loadMore?'more_up':'']" v-if="gameList.length>12">
                        <div class="more_content flex_center" @click="loadMore=!loadMore"> 
                            <span>{{$t('key18')}}</span>
                            <span class="icon_down icon-down-line-free iconfont flex_center" v-show="!loadMore"></span>
                            <span class="icon_down icon-up-line-free iconfont flex_center" v-show="loadMore"></span>
                        </div>
                    </div>
                </div>
                <div v-for="(item,index) in hotClassOption" :key="index">
                    <div class="cdk_container" v-if="item.gameList&&item.gameList.length>0" >
                    <div class="cdk_title flex_sb">
                        <div class="tltle_name">{{ item.classHead.tranName }}</div>
                        <div class="pagination_content flex">
                            <div class="swiper-pagination pagination_state" slot="pagination"></div>
                            <div class="swiper-button-prev iconfont icon-back-line-free btn_icon"></div>
                            <div class="swiper-button-next iconfont icon-enter-line-free btn_icon"></div>
                            
                            
                        </div>
                    </div>
                    <div class="cdk_swiper">
                        <swiper :options="cdkOption" ref="mySwiper2" class="swiper_content">
                            <swiper-slide class="slide_content" v-for="(i,idx) in item.gameList" :key="idx">
                                <div @click="toPage('/details',i.id)">
                                    <div class="slide_img">
                                    <img :src="i.upImage"  alt="" class="slider_img" />
                                    </div>
                                    <div class="slide_title">{{ i.tranName }}</div>
                                    <div class="silde_info flex">
                                        <!-- <div class="info_price"><span>$</span> 36.80</div> -->
                                        <!-- <div class="info_off">
                                            <span>8% OFF</span></div> -->
                                    </div>
                                </div>
                               
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
                </div>
               
                <div class="qs_container">
                    <div class="qs_title">{{$t('key20')}}</div>
                    <div class="qs_group grid">
                        <div class="group_item flex grid_md_4 grid_sm_12" v-for="(item,index) in infoOptions" :key="index">
                            <div class="item_icon">
                                <img :src="item.icon" alt="">
                            </div>
                            <div class="item_info">
                                <div class="item_title">{{ item.title }}</div>
                                <div class="item_text">{{ item.text }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="g_id_signin" id="g_id_signin"></div>
            <!-- <home-footer></home-footer> -->
        </div>
  </div>
 
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

// import homeFooter from '../../components/footer.vue'
import banner1 from '../../assets/image/banner_1.webp'
import banner2 from '../../assets/image/banner_2.webp'
import banner3 from '../../assets/image/banner_3.webp'
import icon1 from '../../assets/image/jyaq.png'
import icon2 from '../../assets/image/zxkf.png'
import icon3 from '../../assets/image/tkbz.png'

export default {
   
    components:{
       swiper,
       swiperSlide,
    
    },
    data(){
        return{
            GOOGLE_CLIENT_ID:'376327036552-5jl4mf4s86v2j3rgbo7eg96u2if0v9ks.apps.googleusercontent.com',
            search:'',
            goods:[],
            showGoods:[],
            name:'',
            swiperOption: {
                loop: false,
                autoplay: false,
                slidesPerView:3,
                spaceBetween: 16,
                // freeMode: true,
                // 显示分页
                pagination: {
                // el: ".swiper-pagination",
                clickable: true, //允许分页点击跳转
                },
                observer:true,  //修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,  //修改swiper的父元素时，自动初始化swiper
                observeSlideChildren:true,
                
                breakpoints: { 
                  //当宽度小于等于320
                  767: {
                    slidesPerView: 1.1,
                  },
  
                },
               
            },
            images:[
                {url:banner2},
                {url:banner1},
                {url:banner3},  
            ],
            bannerList:[],
            pageIndex:1,
            pageSize:20,
            goodsList:[],
            isLastPage:false,
            rateNum:5,
            loadMore:false,
            cdkOption: {
                loop: false,
                autoplay: false,
                slidesPerView:5,
                slidesPerGroup: 5,
                spaceBetween: 16,
                // freeMode: true,
                // 显示分页
               
                pagination:{
                   el:'.swiper-pagination',
                   type :'fraction',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                observer:true,  //修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,  //修改swiper的父元素时，自动初始化swiper
                observeSlideChildren:true,
            
                breakpoints: { 
                  //当宽度小于等于320
                  767: {
                    slidesPerView: 2,
                  },
                  1280: {
                    slidesPerView: 4,
                  },
                  1080: {
                    slidesPerView: 3,
                  },
  
                },
               
            },
            goodsOption: {
                loop: false,
                autoplay: false,
                slidesPerView:1.1,
                // slidesPerGroup: 5,
                // spaceBetween: 16,
                pagination:{
                   el:'.swiper-pagination2',
                },
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev',
                // },
                observer:true,  //修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,  //修改swiper的父元素时，自动初始化swiper
                observeSlideChildren:true,
            
              
               
            },
            infoOptions:[
                {icon:icon1,title:this.$t('key21'),text:this.$t('key22')},
                {icon:icon2,title:this.$t('key23'),text:this.$t('key24')},
                {icon:icon3,title:this.$t('key25'),text:this.$t('key26')},
            ],
            categoryOption:[ ],
            categoryId:'',
            gameList:[],
            swiperGameList:[],
            hotClassOption:[]
        }
    },
    created(){
        this.getBannerList()
        let inviteUserCode = this.$route.query.inviteUserCode
        if(inviteUserCode){
            this.$store.commit('setShowDialog',true)
        }
        this.getData()
       
        console.log('router',this.$route)
    },
    methods:{   
        toPage(path,id){
         let lang = localStorage.getItem('lang')
          if(id){ 
            this.$router.push(path+'/'+lang+'/'+id)
            return
           }
           this.$router.push(path+'/'+lang)      
        },
        getData(){
            let lang =this.$store.state.lang
            let currency = this.$store.state.currency
         
            let _this = this
            if(!lang||!currency){   
                setTimeout(()=>{
                        _this.getData() 
                },1000)
                return
            }
            this.lang = lang
            this.currency = currency
            this.$axiosApi.getGameClassList().then(res=>{
                if(res.code == 200){        
                    let obj = res.data
                    let hotClassOption = []
                    for (let key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            console.log('key',key,obj[key])
                           if(key==1){
                                this.categoryOption = obj[key].map((c,index)=>{
                                    if(!/^\s*$/.test(c.allName)){
                                        c.allName = JSON.parse(c.allName)
                                        c.allName.map(a=>{
                                            if(a.language == lang.dictValue ){
                                                c.tranName = a.name
                                                return
                                            }
                                            if(a.language == '1'){
                                                c.tranName = a.name  
                                               
                                            }
                                        })
                                    }
                                    this.categoryId = obj[key][0].id
                                    this.getMainGoodsList()
                                    return c
                                })          
                            }else{
                               let item = obj[key].map((c,index)=>{
                                    if(!/^\s*$/.test(c.allName)){
                                        c.allName = JSON.parse(c.allName)
                                        c.allName.map(a=>{
                                            if(a.language ==lang.dictValue ){
                                                c.tranName = a.name
                                                return
                                            }
                                            if(a.language == '1'){
                                                c.tranName = a.name                                                
                                            }
                                        })
                                    }
                                    this.getClassOption(obj[key][index].id,index)
                                    hotClassOption.push({'classHead':c})
                                    return c
                                })
                                
                                this.hotClassOption = hotClassOption
                            }
                        }
                    }  
                }
            })
        },
        getMainGoodsList(){
            let lang =this.$store.state.lang
            let currency = this.$store.state.currency
            let _this = this
            if(!lang||!currency){   
                setTimeout(()=>{
                        _this.getMainGoodsList() 
                },1000)
                return
            }
            this.lang = lang
            this.currency = currency
            let pageIndex = this.pageIndex
            let pageSize = this.pageSize
            let gameClass = this.categoryId
            this.$axiosApi.getMainGoodsList({pageIndex,pageSize,gameClass}).then(res=>{
                if(res.code == 200){
                    let lang = this.lang
                    this.gameList = res.data.list.map(v=>{
                        if(!/^\s*$/.test(v.allName)){
                            v.allName = JSON.parse(v.allName)
                            v.allName.map(c=>{
                                if(c.language ==lang.dictValue ){
                                    v.tranName = c.name
                                    return
                                }
                                if(c.language == '1'){
                                    v.tranName = c.name  
                                }
                            })
                        }    
                        return v
                    })
                    let len = res.data.list.length
                    let avLen = Math.ceil(len / 4)
                    let arr =[]      
            
                    for(let i=0;i<avLen;i++){
                        // arr[i] = []
                        arr[i]=( this.gameList.slice(i*4,(i+1)*4))
                    }
                    this.swiperGameList = arr
                }
            })
        },
        getClassOption(id,index){
            let lang =this.$store.state.lang
            let currency = this.$store.state.currency
            let _this = this
            if(!lang||!currency){   
                setTimeout(()=>{
                        _this.getMainGoodsList() 
                },1000)
                return
            }
            this.lang = lang
            this.currency = currency
            let pageIndex = this.pageIndex
            let pageSize = this.pageSize
            let gameClass = id
            this.$axiosApi.getMainGoodsList({pageIndex,pageSize,gameClass}).then(res=>{
                if(res.code == 200){
                    let lang = this.lang
                    let gameList = res.data.list.map(v=>{
                        if(!/^\s*$/.test(v.allName)){
                            v.allName = JSON.parse(v.allName)
                            v.allName.map(c=>{
                                if(c.language ==lang.dictValue ){
                                    v.tranName = c.name
                                    return
                                }
                                if(c.language == '1'){
                                    v.tranName = c.name  
                                    
                                }
                            })
                        }    
                        return v
                    })
                    this.hotClassOption[index].gameList = gameList
                 
                }
            })
        },
        getCategory(id){
            this.categoryId = id
            this.getMainGoodsList()
        },
        getBannerList(){
            let type = 1
            let localeUrl = window.location.host
            let domain = 'https://'+localeUrl
            this.$axiosApi.getBannerList({type,domain}).then(res=>{
                if(res.code==200){
                    this.bannerList = res.data
                }
            })
        }
    },
    computed:{
      // lang(){return this.$store.state.lang},
    }, 
}
</script>

<style lang='scss' scoped>
.index{
    width:100%;
    position: relative;
    flex:1;
    .mobile_search{
        width: 100%;
        .search_box{
            height: 100%;
            position: relative;
            color:$iconColor;
            background:#fff;
            border:1px solid #eff2f5;
            height: 40px;
            line-height: 40px;
            padding:0 16px;
            border-radius: 4px;
            cursor: pointer;
            &:hover{
                opacity: 0.7;
            }
     
            .search_icon{
                margin-right: 4px;
                color:$iconColor;
            }
        }
    }
    .head_swiper{
        width:100%;
        margin:16px 0;
        position: relative;
        .swiper-container{
            border-radius: 4px;
        }
        .swiper_content{
            width:100%;
            .slide_content{
                width:100%;
                border-radius: 4px;
                overflow: hidden;
                .slider_img{
                    transition: all 0.15s ease-out;
                }
               

                &:hover{
                    .slider_img{
                        transform: scale(1.1);
                    }
                    
                }
            }
        }
        .swiper-pagination{
            width:100px;
            left:calc(50% - 50px);
            bottom:4px;
        }
        /deep/.swiper-pagination-bullet{
             background:#dedede;
             margin:0 3px;
             transition: all 0.2s ease-in-out;
             border-radius: 4px;
        }
        /deep/.swiper-pagination-bullet-active{
            background:#f77e1a;
            padding:0 2px;
        }
    }
    .tab_box{
        height: 48px;
        border-radius: 4px;
        background: $white;
        padding-left:24px;
        background-color: #fff;
        position: relative;
        .tab_acticve{
            &::before{
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 32px;
                height: 4px;
                transform: translate(-50%);
                background: #ffd633;
                border-radius: 4px;

            }
        }
        .tab{
            position: relative;
            margin-right:40px;
            color:$text-b2;
            cursor: pointer;
            // overflow-y: scroll;
            transition: all 0.2s ease-in-out;
            height: 100%;
            &:hover{
                color:$text-yellow;
                .icon_tab{
                    transform: scale(1.1); 
                }
            }
            .icon_tab{
                transition: all 0.2s ease-in-out;
                width: 20px;
                height: 20px;
                margin-right:4px;
                
            }
        }
    }
    .pic_thumb{
        margin-top:8px;
        position: relative;
        
        overflow: hidden;
        .pic_tgumb_content{
            max-height: 408px;
        }
        .goods_swiper{
            background:#fff;
            margin-bottom:20px;
            border-radius: 6px;
            .swiper_pagination_content{
                padding:0 4px;
                background: #c7c7c9;
                margin:8px auto 15px;
                display: inline-block;
                border-radius: 24px;
                .swiper-pagination-bullets{
                    height: 18px;
                    line-height: 14px;
                }
            }
            /deep/.swiper-pagination-bullet{
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background:#fff6;
                margin-right:6px;
              
                opacity:1;
                &:last-child{
                    margin:0;
                }
            }
            /deep/.swiper-pagination-bullet-active{
                background:#fff;
            }
            .swiper_content{
               
                .thumb_group{
                    border-radius: 0;
                    padding:16px 0;
                    margin:0 6px;
                    position: relative;
                    border-bottom:1px solid #e8e9eb;
                    &:hover{
                        opacity: 0.7;
                        transform: translateY(0);
                        box-shadow:  none;
                        .group_img{
                            transform:scale(1);
                        }
                    }
                    .group_img{
                        width:64px;
                        height:64px;
                        border-radius: 4px;
                        overflow: hidden;
                    }
                }
                .cell_off{
                    right:0px!important;
                }
            }
        }
        .thumb_group{
            background:#fff;
            border-radius: 4px;
            padding:14px 16px;
            transition: all .1s linear;
            cursor: pointer;
            &:hover{
                transform: translateY(-2px);
                box-shadow:  0px 8px 10px -5px rgba(0, 0, 0, .08), 0px 16px 24px 2px rgba(0, 0, 0, .04), 0px 6px 30px 5px rgba(0, 0, 0, .05);
                .group_img{
                    transform:scale(1.1);
                }
            }
            .group_centent{
                position: relative;
                .group_img{
                    height: 64px;
                    width: 64px;
                    border-radius: 4px;
                    overflow: hidden;
                    margin-right:12px;
                    transition: all .1s linear;
                }
                .group_info{
                    flex:1;
                    .pic_name{
                        color:#0f0f0f;
                        font-size: 15px;
                        line-height: 24px;
                        text-align: left;
                    }
                    .pic_cell{
                        width: 100%;
                        .pic_num{
                            >span{
                                min-width: 21px;
                                padding:0 4px;
                                background: #fc0;
                                color:#000000b2;
                                border-radius: 2px;
                                font-size: 12px;
                                text-align: center;
                                line-height: 16px;
                            }
                            
                            margin-right:4px;
                        }
                        .pic_start{
                            /deep/.el-rate__icon{
                                margin-right:1px;
                                font-size: 16px;
                            }
                        }
                        .pic_review{
                            font-size: 14px;
                            color:#5e626b;
                        }
                    }
                    .pic_sold{
                        text-align: left;
                        font-size: 12px;
                        color:#77808c;
                    }
                }
                .cell_off{
                    font-size: 14px;
                    color:#fff;
                    background:#ff8000;
                    border-radius: 4px;
                    position: absolute;
                    padding:0 6px;
                    top:-10px;
                    right:-12px;
                    line-height: 18px;
                }
            }
        }
        .more_up{
            height: 100px!important;
            background: linear-gradient(180deg, rgba(247, 248, 252, 0) 0, #eff2f5 61.52%, #eff2f5 100%);
            position: absolute;
            left:0;
            right:0;
            bottom:0;
            margin-bottom:0!important;
        }
        .more{
            height: 58px;
            margin-bottom:30px;
            
            .more_content{
                min-width: 160px;
                height: 32px;
                line-height: 30px;
                
                vertical-align: middle;
                background: #fff;
                border:1px solid #d2d3d4;
                color:#0f0f0f;
                font-size: 16px;
                border-radius: 4px;
                cursor: pointer;
                .icon_down{
                    font-size: 12px;
                    margin-left:8px;

                }
            }
        }
    }
    .cdk_container{
        margin-top:16px;
        .cdk_title{
            font-weight: bold;
            .tltle_name{
                font-size:18px;
                color:#1f2128;
            }
            .pagination_content{
                position: relative;
                .swiper-pagination{
                    position: static;
                    margin-right:12px;
                    color:#1f212866;
                    font-size:14px;
                }
                .swiper-button-next,.swiper-button-prev{
                    // background:url();
                    position: static;
                    margin:0;
                }
                .swiper-button-next{
                    margin-left:6px;
                }
                .next{
                    position: static;
                }
                .btn_icon{
                    width: 24px;
                    height: 24px;
                    border:1px solid #d2d3d4;
                    border-radius: 2px;
                    background:#fff;
                    font-size: 16px;
                }
               
            }
        }
        .cdk_swiper{
            margin-top:16px;
            margin-bottom:40px;
            .swiper_content{
                .slide_content{
                    padding:12px;
                    background: #fff;
                    border-radius: 4px;
                    transition: all .1s linear;
                    margin-bottom:40px;
                    &:hover{
                        transform: translateY(-2px);
                        box-shadow:  0px 8px 10px -5px rgba(0, 0, 0, .08), 0px 16px 24px 2px rgba(0, 0, 0, .04), 0px 6px 30px 5px rgba(0, 0, 0, .05);;
                        .slide_img{
                            >img{
                                transform:scale(1.1)
                            }
                           
                        }
                    }
                    .slide_img{
                        border-radius: 6px;
                        overflow: hidden;
                        transition: all .1s linear;
                        >img{
                            transition: all .1s linear;
                        }
                    }
                    .slide_title{
                        font-size: 15px;
                        color: #1f2128;
                        font-weight: 700;
                        line-height: 24px;
                        margin:2px 0;
                        text-align: left;
                    }
                    .silde_info{
                        .info_price{
                            display: flex;
                            align-items: baseline;
                            color:#ff5000;
                            font-size: 20px;
                            font-family: BarlowSemiBold;
                            line-height: 24px;
                            >span{
                                font-size: 12px;
                            }
                        }
                        .info_off{
                            color:#fcfcfc;
                            
                            margin-left:6px;
                            font-weight: 600;
                            // line-height: 20px;
                            >span{
                            background: #ff8000;
                            padding:0 4px;
                            border-radius: 2px;
                            }
                        }
                    }
                }
            }

        }
    }
    .qs_container{
        width: 100%;
        text-align: left;
        .qs_title{
            font-weight: 600;
            line-height: 22px;
            letter-spacing: -.408px;
            color: #1f2128f2;
            font-size: 18px;
        }
        .qs_group{
            grid-column-gap:32px;
            grid-row-gap: 36px;
            margin-top:27px;
            .group_item{
              .item_icon{
                width: 72px;
                height:72px;
                margin-right: 12px;
              }  
              .item_info{
                font-size: 15px;
                flex: 1;
                .item_title{
                    font-weight: 600;
                    line-height: 24px;
                    color:#1f2128;
                }
                .item_text{
                    color:#1f212899;
                    line-height: 22px;
                }
              }
            }
        }
    }
  
}
</style>